<template lang="html" src="./pageSystemDocumentConfigurations.template.vue"></template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSystemDocumentConfigurations.i18n');

export default {
  name: 'PageSystemDocumentConfigurations',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      documentConfigurations: [],
      loading: false,
    };
  },
  computed: {
    isSuperAdmin() {
      return this.$appConfig.user.email === 'sysadmin@cm-technology.io' || this.$appConfig.userAdminRoles.admin.some(
        u => u._id === this.$appConfig.user._id
      );
    }
  },
  created() {
    this.getDocumentConfigurations();
  },
  methods: {
    getDocumentConfigurations() {
      this.$apiInstance.getBeelseDocumentConfigurations()
        .then(data => {
          this.documentConfigurations = data;
        });
    },
    modifyDocumentConfiguration(configuration) {
      const modifyBeelseDocumentConfigurationBody = new this.$BcmModel.ModifyBeelseDocumentConfigurationBody(
        configuration.value
      );
      this.loading = true;

      this.$apiInstance.modifyBeelseDocumentConfiguration(
        configuration._id,
        modifyBeelseDocumentConfigurationBody
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('SuccessfullyModifiedDocumentConfiguration', {dbName: configuration.name}));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loading = false;
        this.getDocumentConfigurations();
      });
    }
  },
};
</script>
