var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-systemDocumentConfigurations"
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('DocumentConfigurations')) + " ")]), _c('v-card-text', [!_vm.isSuperAdmin ? _c('v-alert', {
    attrs: {
      "type": "info",
      "outlined": "",
      "prominent": "",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('OnlyAvailableToSuperAdmin')) + " ")]) : _vm._e(), _vm._l(_vm.documentConfigurations, function (configuration, index) {
    return _c('div', {
      key: index
    }, [_c('div', [_c('span', {
      staticClass: "text-subtitle-2"
    }, [_vm._v(" " + _vm._s(_vm.$t(configuration.name)) + " ")]), _c('span', [_vm._v(" (" + _vm._s(_vm.$t('NameInDB', {
      dbName: configuration.name
    })) + "): ")])]), _c('v-textarea', {
      attrs: {
        "outlined": "",
        "disabled": !_vm.isSuperAdmin
      },
      model: {
        value: configuration.value,
        callback: function ($$v) {
          _vm.$set(configuration, "value", $$v);
        },
        expression: "configuration.value"
      }
    }), _c('v-btn', {
      staticClass: "mr-4 text-none",
      attrs: {
        "color": "error",
        "disabled": !_vm.isSuperAdmin
      },
      on: {
        "click": _vm.getDocumentConfigurations
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(" fas fa-ban ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "success",
        "disabled": !_vm.isSuperAdmin
      },
      on: {
        "click": function ($event) {
          return _vm.modifyDocumentConfiguration(configuration);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(" fas fa-check ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")])], 1), index !== _vm.documentConfigurations.length - 1 ? _c('v-divider', {
      staticClass: "my-4"
    }) : _vm._e()], 1);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }